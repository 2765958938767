"use client";

import React from "react";

const error = () => {
	return (
		<div className="fixed inset-0 z-40 flex size-full flex-col items-center justify-center">
			<h1 className="">Please refresh</h1>
		</div>
	);
};

export default error;
